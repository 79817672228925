import React from 'react';
import { Route} from 'react-router-dom';
import BoatLanding from './BoatPages/BoatLanding';
import BoatCombatLayout from './BoatPages/BoatCombatLayout';


export default function Boats() {
  return(
    <>
        <Route exact path="/boats/layout">
          <BoatCombatLayout/>
        </Route>
        <Route exact path="/boats">
          <BoatLanding/>
        </Route>
    </>
  );
}