import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Button from '../Common/Button';
import Logout from '../Login/Logout'

export default function Navbar( {token, setToken } ) {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const updateWindowDimensions = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
      };
  
      window.addEventListener("resize", updateWindowDimensions);
  
      return () => window.removeEventListener("resize", updateWindowDimensions) 
  
    }, []);
    const [showNavItems, setShowNavItems] = useState(false);
    const updateNavItems = () => {
        setShowNavItems(!showNavItems);
      }
  return(
    <>
        {(width >= 1200)?
            <div className='flex-container'>
                <ul className='navbar flex-row col-12 col-m-12 col-s-12 col-xs-12'>
                    <div className='flex-row col-3 col-m-4 col-s-6 col-xs-8 no-padding heigh-cap'>
                        <li className='height-cap'>
                            <Link to="/" className="link no-padding">
                                <div className='flex-row center no-bottom-padding'>  
                                    <img className='virt-center' src='https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/logo.png' width={53} height={53} alt=''/>
                                    <span className='virt-center fun'>&nbsp;Expanding Journeys</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div className='flex-row col-2 col-m-2 col-s-3 col-xs-4 no-padding'>
                        <li className='no-padding'>
                            <div className='dropdown'>
                                <Link className='link dropbtn nav-item' to="/tools">Tools</Link>
                                <div className='dropdown-content'>
                                    <Link className='link nav-item' to="/tools/combatLayout">Combat Layout</Link>
                                    <Link className='link nav-item' to="/tools/skillcalc">Skill Calc</Link>
                                </div>
                            </div>
                        </li>
                    </div>
                    <div className='flex-row col-2 col-m-2 col-s-3 col-xs-4 no-padding'>
                        <li className='no-padding'>
                            <div className='dropdown'>
                                <Link className='link dropbtn nav-item' to="/monsters">Monsters</Link>
                                <div className='dropdown-content'>
                                    <Link className='link nav-item' to="/monsters/view">View Created Monsters</Link>
                                    <Link className='link nav-item' to="/monsters/create">Create a Monster</Link>
                                </div>
                            </div>
                        </li>
                    </div>
                    <div className='flex-row col-2 col-m-2 col-s-3 col-xs-4 no-padding'>
                        <li className='no-padding'>
                            <div className='dropdown'>
                                <Link className='link dropbtn nav-item' to="/boats">Boats</Link>
                                <div className='dropdown-content'>
                                    <Link className='link nav-item' to="/boats/layout">Boat Layout</Link>
                                </div>
                            </div>
                        </li>
                    </div>
                    {!token ?(
                        <div className='end flex-row col-3 col-m-4 col-s-6 col-xs-8 no-padding'>
                            <li className='no-padding'>
                                <Button as={Link} to="/signup">Sign Up</Button>
                            </li>
                            <li className='no-padding'>
                                <Button as={Link} to="/login">Log In</Button>
                            </li>
                        </div>
                    ) : (
                        <div className='end flex-row col-3  col-m-4 col-s-6 col-xs-8 no-padding'>
                            <li className='no-padding'>
                                <div className='username'>
                                    {token.username}
                                </div>
                            </li>
                            <li className='no-padding'>
                                <Logout setToken={setToken}/>
                            </li>
                        </div>
                    )}
                </ul>
            </div>
        : 
            <>
                <div className="topnav">
                    <Link to="/" className="no-padding active">
                        <div className='flex-row no-bottom-padding'>  
                            <img className='virt-center' src='https://expanding-journeys-web-assets.nyc3.cdn.digitaloceanspaces.com/logo.png' width={53} height={53} alt=''/>
                            <span className='virt-center fun'>&nbsp;Expanding Journeys</span>
                        </div>
                    </Link>

                    <div id="myLinks" className={`${(!showNavItems)? 'hidden' : ''}`}>
                        <Link to="/tools">Tools</Link>
                        <Link to="/monsters">Monsters</Link>
                        <Link to="/boats">Boats</Link>
                        {!token ?
                         <>
                            <Button as={Link} to="/signup">Sign Up</Button>
                            <Button as={Link} to="/login">Log In</Button>
                        </>: 
                        <>
                            <Logout setToken={setToken}/>
                        </>}
                    </div>

                    <button className="icon" onClick={updateNavItems}>
                        <img className='virt-center' src='/menu-burger.png' width={48} height={48} alt=''/>
                    </button>
                </div>
            </>
        }
    </>
  );
}