import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../Common/Button';

export default function BoatLanding() {
   
  return(
    <div className='flex-container'> 
      <div className='flex-row'>
        <h2 className='col-12 no-margin page-header'>Boat Landing</h2>
        <hr></hr>
        <div className='col-12 margin'>
          <Button className="text-btn padding" as={Link} to="/boats/layout">Boat Layout</Button>
        </div>
      </div>
    </div>
  );
}