import React, { useState } from 'react';
import './SkillCalc.css'
import { handleSkillRoll } from '../../Common/utils';
import NumberInput from '../../Common/NumberInput';

export default function SkillCalc() {
    const [skill, setSkill] = useState([{value: 1, validation: null}]);
    const [difficulty, setDifficulty] = useState("automatic");
    const [roll, setRoll] = useState();
    const [rollResult, setRollResult] = useState();
    const [resultReason, setResultReason] = useState();

    function handleCheck() {
        var result = handleSkillRoll(difficulty, skill[0].value);
        setRollResult(result.result);
        setResultReason(result.reason);
        setRoll(result.roll);
    }
  return(
    <>
        <div className='flex-container'>
            <div className='flex-row'>
                <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Skill Calculator</h2>
                <hr></hr>
                <div className='flex-column col-4 col-m-12 col-s-12 col-xs-12'>
                    <NumberInput valueList={skill} setValueList={setSkill} min={1} max={200} label="Enter Skill Value: " />
                        
                        <div>
                            Choose Roll Difficulty:
                            <select id="difficulty" name="difficulty" onChange={e => setDifficulty(e.target.value)}>
                                <option value="automatic">Automatic</option>
                                <option value="veryeasy">Very Easy</option>
                                <option value="easy">Easy</option>
                                <option value="standard">Standard</option>
                                <option value="hard">Hard</option>
                                <option value="formidable">Formidable</option>
                                <option value="herculean">Herculean</option>
                                <option value="hopeless">Hopeless</option>
                            </select>
                        </div>
                    <div>
                        <button className='text-btn no-margin' onClick={handleCheck}>Roll</button>
                    </div>
                    <br></br>
                    You Rolled: {roll}
                    <br></br>
                    Your Check: {rollResult}
                    <br></br>
                    {resultReason}
                </div>
                <div className='flex-row col-8 col-m-12 col-s-12 col-xs-12'>
                    <div className='site-formatted'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Difficulty</th>
                                    <th>Adjusted Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Automatic</td>
                                    <td>Auto Suceed</td>
                                </tr>
                                <tr>
                                    <td>Very Easy</td>
                                    <td>{Math.ceil(skill[0].value * 2)}</td>
                                </tr>
                                <tr>
                                    <td>Easy</td>
                                    <td>{Math.ceil(skill[0].value * 1.5)}</td>
                                </tr>
                                <tr>
                                    <td>Standard</td>
                                    <td>{skill[0].value}</td>
                                </tr>
                                <tr>
                                    <td>Hard</td>
                                    <td>{Math.ceil(skill[0].value * (2/3))}</td>
                                </tr>
                                <tr>
                                    <td>Formidable</td>
                                    <td>{Math.ceil(skill[0].value * (1/2))}</td>
                                </tr>
                                <tr>
                                    <td>Herculean</td>
                                    <td>{Math.ceil(skill[0].value * (1/4))}</td>
                                </tr>
                                <tr>
                                    <td>Hopeless</td>
                                    <td>Auto Fail</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='site-formatted'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Roll</th>
                                    <th>Outcome</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Less than or equal to 1/10th of target</td>
                                    <td>Crit Succeed</td>
                                </tr>
                                <tr>
                                    <td>1-5</td>
                                    <td>Succeed</td>
                                </tr>
                                <tr>
                                    <td>Less than or equal to target</td>
                                    <td>Succeed</td>
                                </tr>
                                <tr>
                                    <td>96-98</td>
                                    <td>Fail</td>
                                </tr>
                                <tr>
                                    <td>Greater than target</td>
                                    <td>Fail</td>
                                </tr>
                                <tr>
                                    <td>99-100</td>
                                    <td>Crit Fail</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>
    
  );
}