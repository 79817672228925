import { useState, useEffect, useRef } from 'react';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import TextInput from '../../Common/TextInput';
import NumberInput from '../../Common/NumberInput';
import { useHistory } from "react-router-dom";
import { getBoats } from '../../../apis/boats';
import { createLobby, joinLobby } from '../../../apis/lobby';
import { handleOnListChange, handleOnCheckboxChange } from '../../Common/utils';

export default function BoatCombatLayout() {
    const history = useHistory();
    const [data, setData] = useState();
    const [errors, setErrors] = useState();


    const [host, setHost] = useState();
    const hostRef = useRef();
    const [redirect, setRedirect] = useState(false);
    const [stompClient, setStompClient] = useState();
    const client = useRef();
    const [roomCode, setRoomCode] = useState([{value: '', validation: null}]);
    const code = useRef();
    const [disconnected, setDisconnected] = useState(false);
    const synced = useRef(false);

    const [cannonOption, setCannonOption] = useState([]);
    const cannonOptionRef = useRef([]);
    const [repairOption, setRepairOption] = useState([]);
    const repairOptionRef = useRef([]);
    const [damage, setDamage] = useState([]);
    const damageRef = useRef([]);
    const [boatOption, setBoatOption] = useState(1);
    const [boats, setBoats] = useState([]);
    const boatsRef = useRef([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [boatName, setBoatName] = useState([]);
    const boatNameRef = useRef([]);
    const [cannons, setCannons] = useState([]);
    const cannonsRef = useRef([]);
    const [locations, setLocations] = useState([]);
    const locationsRef = useRef([]);
    const [waterInput, setWaterInput] = useState([]);
    const waterInputRef = useRef([]);
    const [water, setWater] = useState([]);
    const waterRef = useRef([]);
    const [sailing, setSailing] = useState([]);
    const sailingRef = useRef([]);
    const [sailingInput, setSailingInput] = useState([]);
    const sailingInputRef = useRef([]);
    const [movement, setMovement] = useState([]);
    const movementRef = useRef([]);
    const [movementInput, setMovementInput] = useState([]);
    const movementInputRef = useRef([]);
    const [applyWater, setApplyWater] = useState([]);
    const applyWaterRef = useRef([]);

    const deleteRef = useRef();

    const base = useRef();

    const token = useRef(JSON.parse(localStorage.getItem('token')).token);
    const username = useRef(JSON.parse(localStorage.getItem('token')).username);

    if (process.env.NODE_ENV === 'development') {
        base.current = 'http://localhost:8080/ws';
    } else {
        base.current = 'https://api.expandingjourneys.com/ws';
    }

    if (redirect) {
        history.push('/login');
    }

    const getChangedData = (items, itemsRef, type, listName, position, dataType) => {
        if (type === "add") {
            return {
                type: type,
                sender: username.current,
                listName: listName,
                value: items[position]
            }
        } else if (type === "delete") {
            return {
                type: type,
                sender: username.current,
                position: position,
                listName: listName
            }
        } else {
            if (dataType === "input") {
                let pos = -1;
                for (let i = 0; i < items.length; i++) {
                    if (items[i].value !== itemsRef[i].value) {
                        pos = i;
                    }
                }
                if (pos === -1) {
                    return null;
                }
                return {
                    type: type,
                    sender: username.current,
                    position: pos,
                    listName: listName,
                    value: items[pos]
                }
            } else {
                let pos = -1;
                for (let i = 0; i < items.length; i++) {
                    if (JSON.stringify(items[i]) !== JSON.stringify(itemsRef[i])) {
                        pos = i;
                    }
                }
                if (pos === -1) {
                    return null;
                }
                return {
                    type: type,
                    sender: username.current,
                    position: pos,
                    listName: listName,
                    value: items[pos]
                }
            }
        }
    }

    useEffect(() => {
        let dataUpdates = [];
        let type;
        let position;
        if (boats.length > boatsRef.current.length) {
            position = boats.length - 1;
            type = "add";
        } else if (boats.length < boatsRef.current.length) {
            position = deleteRef.current;
            deleteRef.current = null;
            type = "delete";
        } else {
            type = "update";
        }
        if (JSON.stringify(boatsRef.current) !== JSON.stringify(boats)) {
            let dataUpdate = getChangedData(boats, boatsRef.current, type, "boats", position, "")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            boatsRef.current = [...boats];
        } 
        if (JSON.stringify(boatNameRef.current) !== JSON.stringify(boatName)) {
            let dataUpdate = getChangedData(boatName, boatNameRef.current, type, "boatName", position, "input")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            boatNameRef.current = [...boatName];
        }
        if (JSON.stringify(cannonsRef.current) !== JSON.stringify(cannons)) {
            let dataUpdate = getChangedData(cannons, cannonsRef.current, type, "cannons", position, "")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            cannonsRef.current = [...cannons];
        }
        if (JSON.stringify(locationsRef.current) !== JSON.stringify(locations)) {
            let dataUpdate = getChangedData(locations, locationsRef.current, type, "locations", position, "")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            locationsRef.current = JSON.parse(JSON.stringify(locations));
        }
        if (JSON.stringify(waterRef.current) !== JSON.stringify(water)) {
            let dataUpdate = getChangedData(water, waterRef.current, type, "water", position, "")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            waterRef.current = JSON.parse(JSON.stringify(water));
        }
        if (JSON.stringify(sailingRef.current) !== JSON.stringify(sailing)) {
            let dataUpdate = getChangedData(sailing, sailingRef.current, type, "sailing", position, "")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            sailingRef.current = JSON.parse(JSON.stringify(sailing));
        }
        if (JSON.stringify(movementRef.current) !== JSON.stringify(movement)) {
            let dataUpdate = getChangedData(movement, movementRef.current, type, "movement", position, "")
            if (dataUpdate) {
                dataUpdates.push(dataUpdate);
            }
            movementRef.current = JSON.parse(JSON.stringify(movement));
        }

        if (type !== "update") {
            if (JSON.stringify(cannonOptionRef.current) !== JSON.stringify(cannonOption)) {
                let dataUpdate = getChangedData(cannonOption, cannonOptionRef.current, type, "cannonOption", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                cannonOptionRef.current = JSON.parse(JSON.stringify(cannonOption));
            }
            if (JSON.stringify(repairOptionRef.current) !== JSON.stringify(repairOption)) {
                let dataUpdate = getChangedData(repairOption, repairOptionRef.current, type, "repairOption", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                repairOptionRef.current = JSON.parse(JSON.stringify(repairOption));
            }
            if (JSON.stringify(damageRef.current) !== JSON.stringify(damage)) {
                let dataUpdate = getChangedData(damage, damageRef.current, type, "damage", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                damageRef.current = JSON.parse(JSON.stringify(damage));
            }
            if (JSON.stringify(waterInputRef.current) !== JSON.stringify(waterInput)) {
                let dataUpdate = getChangedData(waterInput, waterInputRef.current, type, "waterInput", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                waterInputRef.current = JSON.parse(JSON.stringify(waterInput));
            }
            if (JSON.stringify(sailingInputRef.current) !== JSON.stringify(sailingInput)) {
                let dataUpdate = getChangedData(sailingInput, sailingInputRef.current, type, "sailingInput", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                sailingInputRef.current = JSON.parse(JSON.stringify(sailingInput));
            }
            if (JSON.stringify(movementInputRef.current) !== JSON.stringify(movementInput)) {
                let dataUpdate = getChangedData(movementInput, movementInputRef.current, type, "movementInput", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                movementInputRef.current = JSON.parse(JSON.stringify(movementInput));
            }
            if (JSON.stringify(applyWaterRef.current) !== JSON.stringify(applyWater)) {
                let dataUpdate = getChangedData(applyWater, applyWaterRef.current, type, "applyWater", position, "")
                if (dataUpdate) {
                    dataUpdates.push(dataUpdate);
                }
                applyWaterRef.current = JSON.parse(JSON.stringify(applyWater));
            }
        }
        
        if (dataUpdates.length > 0 && client.current) {
            client.current.send("/topic/boats/update/" + code.current, {}, JSON.stringify(dataUpdates));
        }
    }, [boats, boatName, cannons, locations, cannonOption, repairOption, damage, water, waterInput, sailing, movement, sailingInput, movementInput, applyWater]);

    const updateList = (setList, listRef, item) => {
        listRef.current[item.position] = JSON.parse(JSON.stringify(item.value))
        setList(JSON.parse(JSON.stringify(listRef.current)));
    }

    const addToList = (setList, listRef, item) => {
        listRef.current.push(JSON.parse(JSON.stringify(item.value)));
        setList(JSON.parse(JSON.stringify(listRef.current)));
    }

    const deleteFromList = (setList, listRef, item) => {
        listRef.current.splice(item.position, 1);
        setList(JSON.parse(JSON.stringify(listRef.current)));
    }

    const handleOnBoatUpdate = (payload) => {
        const json = JSON.parse(payload.body);
        json.forEach((item) => {
            if (item.sender !== username.current) {
                if (item.type === "update") {
                    if (item.listName === "boats") {
                        updateList(setBoats, boatsRef, item);
                    } else if (item.listName === "boatName") {
                        updateList(setBoatName, boatNameRef, item);
                    } else if (item.listName === "cannons") {
                        updateList(setCannons, cannonsRef, item);
                    } else if (item.listName === "locations") {
                        updateList(setLocations, locationsRef, item);
                    } else if (item.listName === "water") {
                        updateList(setWater, waterRef, item);
                    } else if (item.listName === "sailing") {
                        updateList(setSailing, sailingRef, item);
                    } else if (item.listName === "movement") {
                        updateList(setMovement, movementRef, item);
                    }
                } else if (item.type === "add") {
                    if (item.listName === "boats") {
                        addToList(setBoats, boatsRef, item);
                    } else if (item.listName === "boatName") {
                        addToList(setBoatName, boatNameRef, item);
                    } else if (item.listName === "cannons") {
                        addToList(setCannons, cannonsRef, item);
                    } else if (item.listName === "locations") {
                        addToList(setLocations, locationsRef, item);
                    } else if (item.listName === "cannonOption") {
                        addToList(setCannonOption, cannonOptionRef, item);
                    } else if (item.listName === "repairOption") {
                        addToList(setRepairOption, repairOptionRef, item);
                    } else if (item.listName === "damage") {
                        addToList(setDamage, damageRef, item);
                    } else if (item.listName === "water") {
                        addToList(setWater, waterRef, item);
                    } else if (item.listName === "waterInput") {
                        addToList(setWaterInput, waterInputRef, item);
                    } else if (item.listName === "sailingInput") {
                        addToList(setSailingInput, sailingInputRef, item);
                    } else if (item.listName === "movementInput") {
                        addToList(setMovementInput, movementInputRef, item);
                    } else if (item.listName === "sailing") {
                        addToList(setSailing, sailingRef, item);
                    } else if (item.listName === "movement") {
                        addToList(setMovement, movementRef, item);
                    } else if (item.listName === "applyWater") {
                        addToList(setApplyWater, applyWaterRef, item);
                    }
                } else if (item.type === "delete") {
                    if (item.listName === "boats") {
                        deleteFromList(setBoats, boatsRef, item);
                    } else if (item.listName === "boatName") {
                        deleteFromList(setBoatName, boatNameRef, item);
                    } else if (item.listName === "cannons") {
                        deleteFromList(setCannons, cannonsRef, item);
                    } else if (item.listName === "locations") {
                        deleteFromList(setLocations, locationsRef, item);
                    } else if (item.listName === "cannonOption") {
                        deleteFromList(setCannonOption, cannonOptionRef, item);
                    } else if (item.listName === "repairOption") {
                        deleteFromList(setRepairOption, repairOptionRef, item);
                    } else if (item.listName === "damage") {
                        deleteFromList(setDamage, damageRef, item);
                    } else if (item.listName === "water") {
                        deleteFromList(setWater, waterRef, item);
                    } else if (item.listName === "waterInput") {
                        deleteFromList(setWaterInput, waterInputRef, item);
                    } else if (item.listName === "sailingInput") {
                        deleteFromList(setSailingInput, sailingInputRef, item);
                    } else if (item.listName === "movementInput") {
                        deleteFromList(setMovementInput, movementInputRef, item);
                    } else if (item.listName === "sailing") {
                        deleteFromList(setSailing, sailingRef, item);
                    } else if (item.listName === "movement") {
                        deleteFromList(setMovement, movementRef, item);
                    } else if (item.listName === "applyWater") {
                        deleteFromList(setApplyWater, applyWaterRef, item);
                    }
                }
            }
        })
    }
  
    const handleOnBoatSync = (payload) => {
        const json = JSON.parse(payload.body);
        if (hostRef.current) {
            if (json.type === "fullSync" || json.type === "reSync") {
                let type = "fullSyncResponse";
                if (json.type === "reSync") {
                    type = "reSyncResponse";
                }
                const response = {
                    type: type,
                    boats: boatsRef.current,
                    names: boatNameRef.current,
                    cannons: cannonsRef.current,
                    locations: locationsRef.current,
                    cannonOption: cannonOptionRef.current,
                    repairOption: repairOptionRef.current,
                    damage: damageRef.current,
                    water: waterRef.current,
                    waterInput: waterInputRef.current,
                    sailing: sailingRef.current,
                    sailingInput: sailingInputRef.current,
                    movement: movementRef.current,
                    movementInput: movementInputRef.current,
                    applyWater: applyWaterRef.current
                };
                client.current.send("/topic/boats/sync/" + code.current, {}, JSON.stringify(response))
            }
        } else {
            if ((json.type === "fullSyncResponse" ||  json.type === "reSyncResponse") && !synced.current) {
                setData([]);
                setBoats(json.boats);
                boatsRef.current = json.boats;
                setBoatName(json.names);
                boatNameRef.current = json.names;
                setCannons(json.cannons);
                cannonsRef.current = json.cannons;
                setLocations(json.locations);
                locationsRef.current = json.locations;
                setCannonOption(json.cannonOption);
                cannonOptionRef.current = json.cannonOption;
                setRepairOption(json.repairOption);
                repairOptionRef.current = json.repairOption;
                setDamage(json.damage);
                damageRef.current = json.damage;
                setWater(json.water);
                waterRef.current = json.water;
                setWaterInput(json.waterInput);
                waterInputRef.current = json.waterInput;
                setSailing(json.sailing);
                sailingRef.current = json.sailing;
                setSailingInput(json.sailingInput);
                sailingInputRef.current = json.sailingInput;
                setMovement(json.movement);
                movementRef.current = json.movement;
                setMovementInput(json.movementInput);
                movementInputRef.current = json.movementInput;
                setApplyWater(json.applyWater);
                applyWaterRef.current = json.applyWater;
                synced.current = true;
            }
        }
    }

    const onError = (error) => {
        client.current.disconnect();
        client.current = null;
        setStompClient(null);
        setDisconnected(true);
        synced.current = false;
    };


    const websocketHost = async () => {
        const items = await getBoats();
        if (items.status === 401) {
            setRedirect(true);
        } else if (items.status === 200) {
            const lobby = await createLobby();
            if (lobby.status === 401) {
                setRedirect(true);
            } else if (lobby.status === 201) {
                code.current = lobby.body;

                const socket = new SockJS(base.current);
                const stmpClient = over(socket);

                const onConnect = () => {
                    stmpClient.subscribe("/topic/boats/update/" + lobby.body, handleOnBoatUpdate);
                    stmpClient.subscribe("/topic/boats/sync/" + lobby.body, handleOnBoatSync);
                }
        
                stmpClient.connect({'Authorization': `Bearer ${token.current}` }, onConnect, onError);
                client.current = stmpClient;
                hostRef.current = true;
                setStompClient(stmpClient);
                setRoomCode(lobby.body);
                setHost(true);
                setData(items.body);
            } else {
                setErrors(lobby.body.errorMessage);
            }
        } else {
            setErrors(items.body.errorMessage);
        }
    }

    const websocketConnect = async () => {
        hostRef.current = false;
      const lobbyCode = roomCode[0].value;
      const response = await joinLobby(lobbyCode);
      if (response.status === 401) {
          setRedirect(true);
      } else if (response.status === 200) {
        const socket = new SockJS(base.current);
        const stmpClient = over(socket);

        const onConnect = () => {
            stmpClient.subscribe("/topic/boats/update/" + lobbyCode, handleOnBoatUpdate);
            stmpClient.subscribe("/topic/boats/sync/" + lobbyCode, handleOnBoatSync);

            const request = {
                "type": "fullSync"
            }
            stmpClient.send("/topic/boats/sync/" + lobbyCode, {}, JSON.stringify(request))
        }

        stmpClient.connect({'Authorization': `Bearer ${token.current}` }, onConnect, onError);
        client.current = stmpClient;
        code.current = lobbyCode;
        setStompClient(stmpClient);
        setHost(false);
      } else {
          setErrors(response.body.errorMessage);
      }
    }

    const websocketReconnect = async () => {
      const response = await joinLobby(code.current);
      if (response.status === 401) {
          setRedirect(true);
      } else if (response.status === 200) {
        const socket = new SockJS(base.current);
        const stmpClient = over(socket);

        const onConnect = () => {
            stmpClient.subscribe("/topic/boats/update/" + code.current, handleOnBoatUpdate);
            stmpClient.subscribe("/topic/boats/sync/" + code.current, handleOnBoatSync);

            const request = {
                "type": "reSync"
            }
            stmpClient.send("/topic/boats/sync/" + code.current, {}, JSON.stringify(request))
        }

        stmpClient.connect({'Authorization': `Bearer ${token.current}` }, onConnect, onError);
        client.current = stmpClient;
        setStompClient(stmpClient);
        setDisconnected(false);
      } else {
          setErrors(response.body.errorMessage);
      }
    }

    const handleOnBoatAdd = () => {
        if (boats.length <= 8) {
            setErrors();
            let boat = data.find(item => item.id === Number(boatOption))

            let tempBoats = boats;
            tempBoats.push(boat);
            setBoats([...tempBoats]);
    
            let tempBoatName = [...boatName];
            tempBoatName.push({value: boat.name, validation: null});
            setBoatName(tempBoatName);

            let tempCannonOPtions = [...cannonOption];
            tempCannonOPtions.push('Iron Shot');
            setCannonOption(tempCannonOPtions);

            let cannonList = [];
            for (let i = 0; i < boat.cannonStations; i++) {
                cannonList.push('Empty');
            }
            let tempCannons = JSON.parse(JSON.stringify(cannons));
            tempCannons.push(cannonList);
            setCannons(tempCannons);

            let locationList = [];
            locationList.push({name: "Hull", curHit: boat.hullHitPoints, maxHit: boat.hullHitPoints});
            locationList.push({name: "Deck", curHit: boat.deckHitPoints, maxHit: boat.deckHitPoints});
            for (let i = 0; i < boat.mastsNumber; i++) {
                let name = "Mast-" + (i+1)
                locationList.push({name: name, curHit: boat.mastHitPoints, maxHit: boat.mastHitPoints});
            }
            let tempLocations = JSON.parse(JSON.stringify(locations));
            tempLocations.push(locationList);
            setLocations(tempLocations);

            let tempRepairOption = [...repairOption];
            tempRepairOption.push('0');
            setRepairOption(tempRepairOption);

            let tempDamage = [...damage];
            tempDamage.push({value: 0, validation: null});
            setDamage(tempDamage);

            let tempWater = [...water];
            tempWater.push(0);
            setWater(tempWater);

            let tempWaterInput = [...waterInput];
            tempWaterInput.push({value: 0, validation: null});
            setWaterInput(tempWaterInput);

            let tempSailing = [...sailing];
            tempSailing.push(0);
            setSailing(tempSailing);

            let tempSailingInput = [...sailingInput];
            tempSailingInput.push({value: 0, validation: null});
            setSailingInput(tempSailingInput);

            let tempMovement = [...movement];
            tempMovement.push(0);
            setMovement(tempMovement);

            let tempMovementInput = [...movementInput];
            tempMovementInput.push({value: 0, validation: null});
            setMovementInput(tempMovementInput);

            let tempApplyWater = [...applyWater];
            tempApplyWater.push(false);
            setApplyWater(tempApplyWater);
        } else {
            setErrors("Can only have 8 boats added.");
        }
    }

    const handleOnBoatDelete = (position) => {
        setSelectedIndex(0);
        deleteRef.current = position;
    
        let tempBoats = JSON.parse(JSON.stringify(boats));
        tempBoats.splice(position, 1);
        setBoats(JSON.parse(JSON.stringify(tempBoats)));

        let tempBoatName = JSON.parse(JSON.stringify(boatName));
        tempBoatName.splice(position, 1);
        setBoatName(JSON.parse(JSON.stringify(tempBoatName)));

        let tempCannonOptions = JSON.parse(JSON.stringify(cannonOption));
        tempCannonOptions.splice(position, 1);
        setCannonOption(JSON.parse(JSON.stringify(tempCannonOptions)));

        cannonsRef.current.splice(position, 1);
        setCannons(cannonsRef.current);

        locationsRef.current.splice(position, 1);
        setLocations(locationsRef.current);

        let tempRepairOption = JSON.parse(JSON.stringify(repairOption));
        tempRepairOption.splice(position, 1);
        setRepairOption(JSON.parse(JSON.stringify(tempRepairOption)));

        let tempDamage = JSON.parse(JSON.stringify(damage));
        tempDamage.splice(position, 1);
        setDamage(JSON.parse(JSON.stringify(tempDamage)));

        let tempWater = JSON.parse(JSON.stringify(water));
        tempWater.splice(position, 1);
        setWater(JSON.parse(JSON.stringify(tempWater)));

        let tempWaterInput = JSON.parse(JSON.stringify(waterInput));
        tempWaterInput.splice(position, 1);
        setWaterInput(JSON.parse(JSON.stringify(tempWaterInput)));

        let tempSailing = JSON.parse(JSON.stringify(sailing));
        tempSailing.splice(position, 1);
        setSailing(JSON.parse(JSON.stringify(tempSailing)));

        let tempSailingInput = JSON.parse(JSON.stringify(sailingInput));
        tempSailingInput.splice(position, 1);
        setSailingInput(JSON.parse(JSON.stringify(tempSailingInput)));

        let tempMovement = JSON.parse(JSON.stringify(movement));
        tempMovement.splice(position, 1);
        setMovement(JSON.parse(JSON.stringify(tempMovement)));

        let tempMovementInput = JSON.parse(JSON.stringify(movementInput));
        tempMovementInput.splice(position, 1);
        setMovementInput(JSON.parse(JSON.stringify(tempMovementInput)));

        let tempApplyWater = JSON.parse(JSON.stringify(applyWater));
        tempApplyWater.splice(position, 1);
        setApplyWater(JSON.parse(JSON.stringify(tempApplyWater)));
    }

    const handleOnCannonLoad = (position) => {
        let cannonList = JSON.parse(JSON.stringify(cannons));
        let tempCannons = cannonList[position];
        let pos = -1;
        for (let i = tempCannons.length-1; i >= 0; i--) {
            if (tempCannons[i] === 'Empty') {
                pos = i;
            }
        }
        if (pos >= 0) {
            tempCannons[pos] = cannonOption[position];
        }
        cannonList[position] = tempCannons;
        setCannons(JSON.parse(JSON.stringify(cannonList)));
    }

    const handleOnCannonFire = (position, cannonPos) => {
        let cannonList = JSON.parse(JSON.stringify(cannons));
        let tempCannons = cannonList[position];
        tempCannons[cannonPos] = 'Empty';
        cannonList[position] = tempCannons;
        setCannons(JSON.parse(JSON.stringify(cannonList)));
    }

    const handleOnDamage = (position, locationPos) => {
        let locationList = JSON.parse(JSON.stringify(locations));
        let tempLocations = locationList[position];
        tempLocations[locationPos].curHit = parseInt(tempLocations[locationPos].curHit) - damage[position].value;
        locationList[position] = tempLocations;
        setLocations(JSON.parse(JSON.stringify(locationList)));

        console.log(applyWater[position]);
        if (applyWater[position]) {
            let waterToAdd = 0;
            console.log(tempLocations[locationPos]);
            if (tempLocations[locationPos].name === "Deck") {
                waterToAdd = Math.ceil(damage[position].value / 2);
            } else if (tempLocations[locationPos].name === "Hull") {
                waterToAdd = damage[position].value
            }
            let tempWater = [...water];
            tempWater[position] = parseInt(tempWater[position]) + parseInt(waterToAdd);
            setWater(tempWater);
            console.log(waterToAdd);
            console.log(tempWater);
        }
    }

    const handleOnRepair = (position, locationPos) => {
        let locationList = JSON.parse(JSON.stringify(locations));
        let tempLocations = locationList[position];
        tempLocations[locationPos].curHit = parseInt(tempLocations[locationPos].curHit) + Math.ceil(Number(repairOption[position]) * parseInt(tempLocations[locationPos].maxHit));
        if (tempLocations[locationPos].curHit > tempLocations[locationPos].maxHit) {
            tempLocations[locationPos].curHit = tempLocations[locationPos].maxHit;
        }
        locationList[position] = tempLocations;
        setLocations(JSON.parse(JSON.stringify(locationList)));
    }

    const handleOnWaterAdd = (position) => {
        let waterList = [...water];
        waterList[position] = parseInt(waterList[position]) + waterInput[position].value; 
        setWater([...waterList]);
    }

    const handleOnWaterRemove = (position) => {
        let waterList = [...water];
        waterList[position] = parseInt(waterList[position]) - waterInput[position].value;
        if (waterList[position] < 0) {
            waterList[position] = 0;
        } 
        setWater([...waterList]);
    }

    const handleOnSailingAdd = (position) => {
        let sailingList = [...sailing];
        let movementList = [...movement]
        sailingList[position] = parseInt(sailingList[position]) + sailingInput[position].value;
        movementList[position] = movementList[position] + Math.floor(sailingList[position] / boats[position].sailingConversion);
        sailingList[position] = sailingList[position] % boats[position].sailingConversion;
        setSailing([...sailingList]);
        setMovement([...movementList]);
    }

    const handleOnSailingRemove = (position) => {
        let sailingList = [...sailing];
        sailingList[position] = parseInt(sailingList[position]) - sailingInput[position].value;
        if (sailingList[position] < 0) {
            sailingList[position] = 0;
        } 
        setSailing([...sailingList]);
    }

    const handleOnMovementAdd = (position) => {
        let movementList = [...movement];
        movementList[position] = parseInt(movementList[position]) + movementInput[position].value; 
        setMovement([...movementList]);
    }

    const handleOnMovementRemove = (position) => {
        let movementList = [...movement];
        movementList[position] = parseInt(movementList[position]) - movementInput[position].value;
        if (movementList[position] < 0) {
            movementList[position] = 0;
        } 
        setMovement([...movementList]);
    }

  return(
    <div className='flex-container'> 
      <div className='flex-row'>
        <h2 className='col-12 col-m-12 col-s-12 col-xs-12 no-margin page-header'>Boat Layout</h2>
        <hr></hr>
        {!stompClient ?
            <>
                {disconnected ? 
                    <div className='col-12 col-m-12 col-s-12 col-xs-12'>
                        <button className='text-btn no-margin' onClick={() => websocketReconnect()} >Reconnect</button>
                    </div>
                :   
                    <>
                        <div className='col-6 col-m-6 col-s-12 col-xs-12'>
                            <TextInput valueList={roomCode} setValueList={setRoomCode} validationFormat={'strict'} length={5}/>
                            <button className='text-btn no-margin' onClick={() => websocketConnect()} >Join</button>
                        </div>
                        <div className='col-6 col-m-6 col-s-12 col-xs-12'>
                            <button className='text-btn' onClick={() => websocketHost()} >Host</button>
                        </div>
                    </> 
                }

            </>
        :
            <>
                {data && 
                    <>
                        {host && (
                            <>
                                <div className='col-12 col-m-12 col-s-12 col-xs-12'>
                                    {`Room Code: ${roomCode}`}
                                </div>
                                <div className='col-12 col-m-12 col-s-12 col-xs-12'>
                                    <div className='no-padding col-12 col-m-12 col-s-12 col-xs-12'>
                                        {errors}
                                    </div>
                                    {`Select Boat: `}
                                    <select id="boatOption" name="boatOption" onChange={(e) => setBoatOption(e.target.value)} defaultValue={boatOption}>
                                        {data.map((item) => (
                                            <option key={item.id} value={item.id}>{`${item.name}(${item.type})`}</option>
                                        ))}
                                    </select>
                                    <button className="text-btn-s" onClick={handleOnBoatAdd}>Add</button>
                                </div>
                            </>
                        )}

                        <div key={boats.length} className='no-padding col-12 col-m-12 col-s-12 col-xs-12'>
                            <div className='no-bottom-padding col-12 col-m-12 col-s-12 col-xs-12'>
                            {boats.length > 0 &&
                                <div className='col-12 col-m-12 col-s-12 col-xs-12 border no-bottom-padding no-left-padding'>
                                {boatName.map((boat, boatIndex) => (
                                    <button className={`${selectedIndex === boatIndex ? 'active-tab': 'tab'}`} onClick={() => setSelectedIndex(boatIndex)}>{boat.value}</button>
                                ))}
                                </div>
                            }
                            </div>
                            <div className='padding'>
                            {boats.map((boat, boatIndex) => (
                                <div className={`flex-row border ${!(selectedIndex === boatIndex) && 'hidden'}`}>
                                    {host && (
                                        <div className="col-12 col-m-12 col-s-12 col-xs-12">
                                            <table className='hidden-table no-padding'>
                                                <tbody>
                                                <tr>
                                                    <td className='hidden-table no-padding'>
                                                    <TextInput position={boatIndex} valueList={boatName} setValueList={setBoatName} validationFormat={'strict'} length={30}/>
                                                    </td>
                                                    <td className='hidden-table no-padding'><button className="x-btn" onClick={() => handleOnBoatDelete(boatIndex)}>x</button></td>
                                                </tr>  
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <div className="flex-column col-12 col-m-12 col-s-12 col-xs-12">
                                        <div>{`Ship Size: ${boat.type}`}</div>
                                        <br/>
                                        <div>{`Sailing Stations: ${boat.sailingStations}`}</div>
                                        <div>{`Bilging Stations: ${boat.bilgeStations}`}</div>
                                        <div>{`Cannon Stations: ${boat.cannonStations}`}</div>
                                        <br/>
                                        <div>{`Attacker Move Bonus: ${boat.attackerMoveBonus}`}</div>
                                        <div>{`Attacker Cannon Bonus: ${boat.attackerCannonBonus}`}</div>
                                    </div>
                                    <div className="flex-column col-3 col-m-3 col-s-12 col-xs-12">
                                        <div>{`Cannon Size: ${boat.cannonSize}`}</div>
                                        {boat.cannonSize === "Small" ? 
                                            <>
                                                <div>Iron Shot Damage: 8</div>
                                                <div>Grape Shot Damage: 5</div>
                                                <div>Chain Shot Damage: 3</div>
                                                <div>Net Shot Damage: 0</div>
                                            </>
                                            :
                                            <>
                                                {boat.cannonSize === "Medium" ?
                                                <>
                                                    <div>Iron Shot Damage: 12</div>
                                                    <div>Grape Shot Damage: 8</div>
                                                    <div>Chain Shot Damage: 4</div>
                                                    <div>Net Shot Damage: 0</div>
                                                </>
                                                :
                                                <>
                                                    <div>Iron Shot Damage: 15</div>
                                                    <div>Grape Shot Damage: 10</div>
                                                    <div>Chain Shot Damage: 5</div>
                                                    <div>Net Shot Damage: 0</div>
                                                </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="flex-column col-9 col-m-9 col-s-12 col-xs-12">
                                        {`Select Cannon Type: `}
                                        <div>
                                            <select onChange={(e) => handleOnListChange( boatIndex, cannonOption, setCannonOption, e.target.value)} defaultValue={cannonOption[boatIndex]}>
                                                    <option value={"Iron Shot"}>Iron Shot</option>
                                                    <option value={"Grape Shot"}>Grape Shot</option>
                                                    <option value={"Chain Shot"}>Chain Shot</option>
                                                    <option value={"Net Shot"}>Net Shot</option>
                                            </select>
                                            <button className="text-btn-s" onClick={() => handleOnCannonLoad(boatIndex)}>Load</button>
                                        </div>
                                        <div className='small-text side-scroll site-formatted'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Cannon</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cannons[boatIndex].map((cannonStatus, cannonIndex) => (
                                                        <tr>
                                                            <td>{`Cannon-${cannonIndex+1}`}</td>
                                                            <td>{cannonStatus}</td>
                                                            <td>
                                                                {cannonStatus !== "Empty" && 
                                                                    <button className="text-btn-s" onClick={() => handleOnCannonFire(boatIndex, cannonIndex)}>Fire</button>
                                                                }
                                                            </td>
                                                        </tr>))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="flex-column col-3 col-m-3 col-s-12 col-xs-12">
                                        <div>{`Repair Modifiers:`}</div>
                                        <div>{`Minor Wound: +${boat.minorRepairModifier} Grades Harder`}</div>
                                        <div>{`Serious Wound: +${boat.seriousRepairModifier} Grades Harder`}</div>
                                        <div>{`Critical Wound: +${boat.criticalRepairModifier} Grades Harder`}</div>
                                    </div>
                                    <div className="flex-column col-9 col-m-9 col-s-12 col-xs-12">
                                        <div>
                                            <NumberInput position={boatIndex} valueList={damage} setValueList={setDamage} min={0} max={200} label=" Enter Damage: " />
                                            {`Select Repair Amount:`}
                                            <select onChange={(e) => handleOnListChange( boatIndex, repairOption, setRepairOption, e.target.value)} defaultValue={repairOption[boatIndex]}>
                                                    <option value={"0"}>0%</option>
                                                    <option value={".25"}>25%</option>
                                                    <option value={".5"}>50%</option>
                                                    <option value={"1"}>100%</option>
                                            </select>
                                        </div>
                                        <div>
                                            {`Apply Water On Damage?`}
                                            <input type="checkbox" checked={applyWater[boatIndex]} onChange={() => handleOnCheckboxChange(boatIndex, applyWater, setApplyWater)}/>
                                        </div>
                                        <div className='small-text side-scroll site-formatted'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Location</th>
                                                        <th>Current / Max HP</th>
                                                        <th>Wounds</th>
                                                        <th>Repair Difficulty</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {locations[boatIndex].map((location, locationIndex) => (
                                                        <tr>
                                                            <td>{location.name}</td>
                                                            <td>{`${location.curHit}/${location.maxHit}`}</td>
                                                            <td>
                                                                {locations[boatIndex][locationIndex].curHit >= locations[boatIndex][locationIndex].maxHit ?
                                                                    <>
                                                                        {``}
                                                                    </>  
                                                                :
                                                                    <>
                                                                        {locations[boatIndex][locationIndex].curHit > Math.ceil(locations[boatIndex][locationIndex].maxHit / 2) ?
                                                                            <>
                                                                                {`Minor`}
                                                                            </>  
                                                                        :
                                                                            <>
                                                                                {locations[boatIndex][locationIndex].curHit > 0 ?
                                                                                    <>
                                                                                        {`Serious`}
                                                                                    </>  
                                                                                :
                                                                                    <>
                                                                                        {`Critical`}
                                                                                    </>}
                                                                            </>}
                                                                    </>}
                                                            </td>
                                                            <td>
                                                                {locations[boatIndex][locationIndex].curHit >= locations[boatIndex][locationIndex].maxHit ?
                                                                    <>
                                                                        {``}
                                                                    </>  
                                                                :
                                                                    <>
                                                                        {locations[boatIndex][locationIndex].curHit > Math.ceil(locations[boatIndex][locationIndex].maxHit / 2) ?
                                                                            <>
                                                                                {`+${boats[boatIndex].minorRepairModifier} Grades Harder`}
                                                                            </>  
                                                                        :
                                                                            <>
                                                                                {locations[boatIndex][locationIndex].curHit > 0 ?
                                                                                    <>
                                                                                        {`+${boats[boatIndex].seriousRepairModifier} Grades Harder`}
                                                                                    </>  
                                                                                :
                                                                                    <>
                                                                                        {`+${boats[boatIndex].criticalRepairModifier} Grades Harder`}
                                                                                    </>}
                                                                            </>}
                                                                    </>}
                                                            </td>
                                                            <td>
                                                                <button className='text-btn no-left-margin' onClick={() => handleOnDamage(boatIndex, locationIndex)}>Damage</button>
                                                                <button className='text-btn no-left-margin' onClick={() => handleOnRepair(boatIndex, locationIndex)}>Repair</button>
                                                            </td>
                                                        </tr>))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="flex-column col-3 col-m-3 col-s-12 col-xs-12">
                                        <div>Water Thresholds:</div>
                                        <div>{`No Penalty: 0-${boat.waterThreshold1-1}`}</div>
                                        <div>{`-2 Saling Points: ${boat.waterThreshold1}-${boat.waterThreshold2-1}`}</div>
                                        <div>{`-4 Saling Points: ${boat.waterThreshold2}-${boat.waterThreshold3-1}`}</div>
                                        <div>{`Ship Sinks: ${boat.waterThreshold3}+`}</div>
                                    </div>
                                    <div className="flex-column col-9 col-m-9 col-s-12 col-xs-12">
                                        <NumberInput position={boatIndex} valueList={waterInput} setValueList={setWaterInput} min={0} max={1000} label=" Enter Water: " />
                                        <div>
                                            <button className='text-btn no-left-margin' onClick={() => handleOnWaterAdd(boatIndex)}>Add Water</button>
                                            <button className='text-btn no-left-margin' onClick={() => handleOnWaterRemove(boatIndex)}>Remove Water</button>
                                        </div>
                                        <div>{`Current Water: ${water[boatIndex]}`}</div>
                                        <div>
                                            {water[boatIndex] < boat.waterThreshold1 ?
                                                <>
                                                    {`No Penalty: 0-${boat.waterThreshold1-1}`}
                                                </>  
                                            :
                                                <>
                                                    {water[boatIndex] < boat.waterThreshold2 ?
                                                        <>
                                                            {`-2 Saling Points: ${boat.waterThreshold1}-${boat.waterThreshold2-1}`}
                                                        </>  
                                                    :
                                                        <>
                                                            {water[boatIndex] < boat.waterThreshold3 ?
                                                                <>
                                                                    {`-4 Saling Points: ${boat.waterThreshold2}-${boat.waterThreshold3-1}`}
                                                                </>  
                                                            :
                                                                <>
                                                                    {`Ship Sinks: ${boat.waterThreshold3}+`}
                                                                </>}
                                                        </>}
                                                </>}
                                        </div>                                            
                                    </div>
                                    <div className="flex-column col-3 col-m-3 col-s-12 col-xs-12">
                                        <div>{`Sailing Conversion Rate: ${boat.sailingConversion}`}</div>
                                    </div>
                                    <div className="flex-column col-9 col-m-9 col-s-12 col-xs-12">
                                        <NumberInput position={boatIndex} valueList={sailingInput} setValueList={setSailingInput} min={0} max={1000} label=" Enter Sailing: " />
                                        <div>
                                            <button className='text-btn no-left-margin' onClick={() => handleOnSailingAdd(boatIndex)}>Add Sailing</button>
                                            <button className='text-btn no-left-margin' onClick={() => handleOnSailingRemove(boatIndex)}>Remove Sailing</button>
                                        </div>
                                        <NumberInput position={boatIndex} valueList={movementInput} setValueList={setMovementInput} min={0} max={100} label=" Enter Movement: " />
                                        <div>
                                            <button className='text-btn no-left-margin' onClick={() => handleOnMovementAdd(boatIndex)}>Add Movement</button>
                                            <button className='text-btn no-left-margin' onClick={() => handleOnMovementRemove(boatIndex)}>Remove Movement</button>
                                        </div>
                                        <div>{`Current Sailing Points: ${sailing[boatIndex]}`}</div>
                                        <div>{`Current Movement: ${movement[boatIndex]}`}</div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </>
                }
            </>
        }
      </div>
    </div>
  );
}