import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import './App.css';
import Navbar from '../Navbar/Navbar';
import Home from '../Home/Home'
import Login from '../Login/Login';
import Signup from '../Login/Signup';
import useToken from './useToken';
import Tools from '../Tools/Tools';
import Monsters from '../Monsters/Monsters';
import Boats from '../Boats/Boats';

function App() {
  const { token, setToken } = useToken();
  const [redirect, setRedirect]  = useState(false);
  const history = useHistory();


  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (isTokenExpired(token.token)) {
        setToken();
        localStorage.removeItem('token');
        setRedirect(true);
      }
    } 
  }, [token, setToken]);

  if (redirect) {
    history.pushState('/');
  } 
  return (
    <>
        <BrowserRouter>
          <Navbar token={token} setToken={setToken}/>
          <div className="wrapper">
            <Switch>
              <Route path="/signup">
                {token ?(
                  <Home />
                ) : (
                  <Signup />
                )}
              </Route>
              <Route path="/login">
                  <Login setToken={setToken}/>
              </Route>
              <Route path="/tools">
                {token ?(
                  <Tools/>
                ) : (
                  <Login setToken={setToken}/>
                )}
              </Route>
              <Route path="/monsters">
                {token ?(
                  <Monsters/>
                ) : (
                  <Login setToken={setToken}/>
                )}
              </Route>
              <Route path="/boats">
                {token ?(
                  <Boats/>
                ) : (
                  <Login setToken={setToken}/>
                )}
              </Route>
              <Route path="/">
                  <Home />
              </Route>
            </Switch>
          </div>
        </BrowserRouter>
    </>
  );
}

export default App;